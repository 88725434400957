import React from 'react';

export const Roles = ({ roles }: { roles: any[] }) => {
  return (
    <div>
      {/*<Timeline align="left" className={'roles-timeline'}>*/}
      {/*  {roles.map((role) => (*/}
      {/*    <TimelineItem key={role.congress}>*/}
      {/*      <TimelineOppositeContent>*/}
      {/*        <Typography color="textSecondary">{getYear(role.end_date)}</Typography>*/}
      {/*      </TimelineOppositeContent>*/}
      {/*      <TimelineSeparator>*/}
      {/*        <TimelineDot />*/}
      {/*        <TimelineConnector />*/}
      {/*      </TimelineSeparator>*/}
      {/*      <TimelineContent>*/}
      {/*        <Button>*/}
      {/*          {role.title} - {role.state}*/}
      {/*          <br />*/}
      {/*          Congress {role.congress}*/}
      {/*        </Button>*/}
      {/*      </TimelineContent>*/}
      {/*    </TimelineItem>*/}
      {/*  ))}*/}
      {/*</Timeline>*/}
    </div>
  );
};
