import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import axios from "axios";

/**
 * Submit
 * Created on 2/5/24
 */
const SubmitPage = () => {
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, reset} = useForm();

    const onSubmit = (data) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API}/submit`, data).then(result => {
            if (result.data) {
                alert('Your submission has been saved and is currently under review!');
                reset();
            }
        }).catch(err => {
            console.log('something went wrong: ', err);
        }).finally(() => setLoading(false));
    }

    return (
        <div className={"container-sm pt-5"}>
            <Link to={'../'}>
                <span className={'text-lg text-blue-500 font-bold'}>&lt; Back</span>
            </Link>

            <p className={"text-lg mt-4 mb-2"}>Know of any event that has happened that is missing from our database? Submit it below and we'll add it.</p>
            <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col space-y-4"}>
                <label>
                    <strong>Title</strong>
                    <input type="text" {...register('title', {required: true})} className={"input"} />
                </label>

                <label>
                    <strong>Who was involved?</strong>
                    <input type="text" {...register('entities')} className={"input"} />
                    <small>Any corporations, government officials, public figures, etc.</small>
                </label>

                <label>
                    <strong>Summarize the event</strong>
                    <textarea rows={5} {...register('summary', {required: true})} className={"input"}></textarea>
                </label>

                <div className={"flex justify-end space-x-4"}>
                    <Link to={"../"} className={"px-3 py-2 rounded border border-gray-300"}>Cancel</Link>
                    <button type={"submit"} disabled={loading || false} className={"button"}>Submit</button>
                </div>
            </form>
        </div>
    );
}

export default SubmitPage;
