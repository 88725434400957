import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import EventCard from "../Components/EventCard";
import Loading from "../Components/Loading";

const HomePage = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    const [drafts, setDrafts] = useState([]);

    useEffect(() => {
        const getArticles = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API}/events`);

                if (result.data) {
                    setArticles(result.data);
                }
            } catch (error) {
                console.log('Could not retrieve articles');
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        getArticles();
    }, []);

    useEffect(() => {
        try {
            if (process.env.NODE_ENV !== "development") return;

            axios.get(`${process.env.REACT_APP_API}/drafts`).then(result => {
                if (result.data) {
                    setDrafts(result.data);
                }
            }).catch(err => console.log(err));
        } catch (err) {
            console.log(err);
        }
    }, []);

    return (
        <div className="container mx-auto px-4 lg:px-0">
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
                        {articles.map((event: any) => (
                            <Link to={`/event/${event.id}`} key={event.id}>
                                <EventCard event={event}/>
                            </Link>
                        ))}
                    </div>

                    <hr className="my-10"/>

                    {
                        process.env.NODE_ENV === "development" && (
                            <>
                                <h2 className={"text-4xl mb-6"}>Drafts</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
                                    {drafts.map((event: any) => (
                                        <Link to={`/event/${event.id}`} key={event.id}>
                                            <EventCard event={event}/>
                                        </Link>
                                    ))}
                                </div>
                            </>
                        )
                    }
                </>
            )}
        </div>
    );
};

export default HomePage;
