import React from 'react';
import {Link} from "react-router-dom";

const ArticleCard = ({article}) => {
    return (
        <div className="rounded-md shadow-lg bg-white overflow-hidden">
            <div className="flex flex-col items-start h-full mb-4">
                {article?.cover && (
                    <img src={article.cover} alt=""/>
                )}

                <div className="px-3 py-2 flex flex-col h-full w-full">
                    <h2 className="text-lg font-semibold leading-6">{
                        article?.title.length > 100 ? article?.title.substring(0, 100)+"..." : article?.title
                    }</h2>
                    {/*<p>{formatDate(article?.created)}</p>*/}

                    {
                        article?.domain ? (
                            <div className={'flex justify-between items-center mt-auto mb-3'}>
                                <span>{article.domain}</span>
                                <a className={"button"} href={article?.url} target={"_blank"}>Read</a>
                            </div>
                        ) : (
                            <div>
                                <Link to={`/article/${article?.id}`}>Read</Link>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ArticleCard;
