import './App.css';
import {Routes, Route, Link, NavLink} from 'react-router-dom';
import HomePage from './Pages/Home';
import Hero from './Components/Hero';
import BillsPage from './Pages/BillsPage';
import ProfilePage from './Pages/ProfilePage';
import MembersPage from './Pages/MembersPage';
import ArticlePage from './Pages/Articles/ArticlePage';
import EventPage from './Pages/Event/EventPage';
import Footer from './Components/Footer';
import {useLocation} from 'react-router-dom';
import AboutPage from "./Pages/AboutPage";
import React from "react";
import SubmitPage from "./Pages/SubmitPage";
import SupportPage from "./Pages/SupportPage";
import {GiArchiveResearch, GiCapitol} from "react-icons/gi";
import {FaChurch} from "react-icons/fa6";
import PastorsList from "./Pages/Church/PastorsList";

function App() {
    const location = useLocation();

    return (
        <>
            {/*<Navbar />*/}
            <header className={"bg-slate-800 py-3 px-4 lg:px-0"}>
                <div className="container mx-auto text-right">
                    <nav>
                        <ul className={"flex space-x-4 justify-end text-white"}>
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/submit"}>Submit</Link></li>
                            <li><Link to={"/support"}>Support</Link></li>
                            <li><Link to={"/about"}>About</Link></li>
                        </ul>
                    </nav>
                </div>
            </header>
            {/* Hide navigation and hero in given routes */}
            {['event', 'members/member'].every((route) => !location.pathname.includes(route)) ? (
                <>
                    <Hero/>
                    {/*<CategoryList />*/}
                    <nav className="flex justify-center items-center mb-6">
                        <ul className={"flex space-x-6"}>
                            <li>
                                <NavLink to={'/'} className={({isActive}) => isActive ? "flex flex-col items-center text-slate-700" : "flex flex-col items-center"}>
                                    <GiArchiveResearch size={24}/>
                                    <span>Cases</span>
                                </NavLink>
                            </li>

                            {/*<li>*/}
                            {/*    <NavLink to={'/members'} className={({isActive}) => isActive ? "flex flex-col items-center text-slate-700" : "flex flex-col items-center"}>*/}
                            {/*        <GiCapitol size={24}/>*/}
                            {/*        <span>Government</span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                            <li>
                                <NavLink to={'/church'} className={({isActive}) => isActive ? "flex flex-col items-center text-slate-700" : "flex flex-col items-center"}>
                                    <FaChurch size={24}/>
                                    <span>The Church</span>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </>
            ) : null}

            <main>
                <Routes>
                    <Route path="/" element={<HomePage/>} />
                    <Route path="/event/:eventId" element={<EventPage/>}/>
                    <Route path="/article/:articleId" element={<ArticlePage/>}/>
                    <Route path="/submit" element={<SubmitPage />}/>
                    <Route path="/bills" element={<BillsPage/>}/>
                    <Route path="/members" element={<MembersPage />}/>
                    <Route path="/members/member/:memberId" element={<ProfilePage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/support" element={<SupportPage/>}/>
                    <Route path="/church" element={<PastorsList/>}/>

                    {/*<Route path="/" element={<ProfilePage />} />*/}

                    {/* <Route path="/house" element={<ProfileList type="house" />} /> */}
                    {/* <Route path="/committees" element={<CommitteeList />} /> */}
                    {/* <Route path="/map/:mapId" element={<MapView />} /> */}
                    {/* <Route path="/donate" element={<DonationsPage />} /> */}
                </Routes>
            </main>

            <Footer/>
        </>
    );
}

export default App;
