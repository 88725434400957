import React from 'react';

const EventCard = ({ event }) => {
  return (
    <div className="rounded-md md bg-white overflow-hidden shadow-md transition-shadow hover:shadow-lg">
      <div className="flex flex-col">
        {event.cover && (
          <div
            style={{
              backgroundImage: `url(${event.cover})`,
              height: '250px',
            }}
            className="bg-cover bg-center overflow-hidden"
          ></div>
        )}

        <div className="px-3 pt-2 pb-4">
          <h2 className="text-xl font-semibold leading-6 mb-2">{event.title}</h2>
          <p className={"text-sm"}>{event.summary?.substring(0, 175)}{event?.summary?.length > 175 ? "..." : ""}</p>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
