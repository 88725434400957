import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {getAge} from '../utils';
import {Link} from 'react-router-dom';
import Loading from "../Components/Loading";
import {cache, getCached, isCached} from "../Services/Cache";

const MembersPage = () => {
    const [houseMembers, setHouseMembers] = useState<any>([]);
    const [senateMembers, setSenateMembers] = useState<any>([]);

    const [selectedChamber, setSelectedChamber] = useState<'house'|'senate'>('house');
    // const [selectedParty, setSelectedParty] = useState<'All'|'D'|'R'>('All');

    const [filteredMembers, setFilteredMembers] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);

    useEffect(() => {
        setLoading(true);

        const endpoint1 = '/gov/house';
        const endpoint2 = '/gov/senate';

        const request1 = axios.get(`${process.env.REACT_APP_API}${endpoint1}`);
        const request2 = axios.get(`${process.env.REACT_APP_API}${endpoint2}`);

        if (isCached(endpoint1)) {
            setSenateMembers(getCached(endpoint1));
            setFilteredMembers(getCached(endpoint1));
            setLoading(false);
        } else {
            request1.then(result => {
                setSenateMembers(result.data);
                cache(endpoint1, result.data);

                setFilteredMembers(result.data);
                setLoading(false);
            }).catch(console.log);
        }

        if (isCached(endpoint2)) {
            setHouseMembers(getCached(endpoint2));
            setLoading(false);
        } else {
            request2.then(result => {
                setHouseMembers(result.data);
                cache(endpoint2, result.data);
            }).catch(console.log)
        }
    }, []);

    const handleMemberTypeChange = (value: 'house' | 'senate') => {
        setSelectedChamber(value);

        if (value === 'house') {
            setFilteredMembers((current: any[]) => [...houseMembers]);
        } else {
            setFilteredMembers((current: any[]) => [...senateMembers]);
        }
    };

    // const handleRadioChange = (value: 'All' | 'D' | 'R') => {
    //     if (value === 'All') {
    //         setFilteredMembers(() => selectedChamber === 'house' ? houseMembers : senateMembers);
    //     } else {
    //         setFilteredMembers(() => selectedChamber === 'house' ? houseMembers.filter((m: any) => m.party === value) : senateMembers.filter((m: any) => m.party === value));
    //     }
    // };

    const handleSearch = (event: any) => {
        const filter = event.target.value.toLowerCase();

        if (filter) {
            const filtered = filteredMembers.filter(
                (member: any) => member.first_name.toLowerCase().includes(filter) || member.last_name.toLowerCase().includes(filter)
            );
            setFilteredMembers(filtered);
        } else {
            setFilteredMembers(selectedChamber === 'house' ? houseMembers : senateMembers);
        }
    };

    return (
        <div className={"container mx-auto px-4"}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="card mb-4">
                        <div
                            className="flex flex-col md:flex-row justify-between align-middle items-center space-x-4">
                            <div className="w-full">
                                <input type="text" placeholder="Search..." onChange={handleSearch}
                                       className="input" />
                            </div>

                            <div className={"flex-shrink-0 space-x-4 border-l pl-4"}>
                                <label className={"py-2 inline-block"}>
                                    <input type="radio" name="chamber" checked={selectedChamber === 'house'} value={selectedChamber} className="mr-1"
                                           onChange={(e: any) => handleMemberTypeChange('house')}/>
                                    House
                                </label>
                                <label className={"py-2 inline-block"}>
                                    <input type="radio" name="chamber" checked={selectedChamber === 'senate'} value={selectedChamber} className="mr-1"
                                           onChange={(e: any) => handleMemberTypeChange('senate')}/>
                                    Senate
                                </label>
                            </div>

                            {/*<div className="flex-shrink-0 space-x-4 border-l pl-4">*/}
                            {/*    <label className={"py-2 inline-block"}>*/}
                            {/*        <input type="radio" name="party" value={selectedParty} className="mr-1"*/}
                            {/*               onChange={() => handleRadioChange('All')}/>*/}
                            {/*        All*/}
                            {/*    </label>*/}
                            {/*    <label className={"py-2 inline-block"}>*/}
                            {/*        <input type="radio" name="party" value={selectedParty} className="mr-1"*/}
                            {/*               onChange={() => handleRadioChange('D')}/>*/}
                            {/*        Democrats*/}
                            {/*    </label>*/}
                            {/*    <label className={"py-2 inline-block"}>*/}
                            {/*        <input type="radio" name="party" value={selectedParty} className="mr-1"*/}
                            {/*               onChange={() => handleRadioChange('R')}/>*/}
                            {/*        Republicans*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {filteredMembers.map((member: any) => (
                            <div key={member.id}
                                 className="card p-0 overflow-hidden transition-all hover:shadow-lg">
                                <div className={"flex space-x-4"}>
                                    <div className={"pt-1 pb-4 pl-4"}>
                                        <Link
                                            to={{pathname: `member/${member.id}`}}
                                            state={{api_uri: member.api_uri}}
                                            className={"text-slate-800 font-semibold text-2xl hover:underline"}
                                        >
                                            {member.last_name}, {member.first_name} {member.middle_name}
                                        </Link>
                                        <p>Age: {getAge(member.date_of_birth)}</p>
                                        <p>State: {member.state}</p>
                                        <p>Party: {member.party}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default MembersPage;
