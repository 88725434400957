export const PASTORS = [
    {
        name: "Edir Macedo",
        wealth: "$1.2 billion",
        description: "Founder of the Universal Church of the Kingdom of God and owner of Rede Record.",
        location: "Brazil",
        profile: "/pastors/Edir_Macedo.jpg"
    },
    {
        name: "Alph Lukau",
        wealth: "$1 billion",
        description: "Founder of Alleluia Ministries International.",
        location: "South Africa"
    },
    {
        name: "Valdemiro Santiago",
        wealth: "$350 million",
        description: "Leader of the World Church of the Power of God.",
        location: "Brazil"
    },
    {
        name: "Kenneth Copeland",
        wealth: "$300 million",
        description: "American televangelist and founder of Kenneth Copeland Ministries.",
        location: "USA"
    },
    {
        name: "David Oyedepo",
        wealth: "$150 million",
        description: "Founder of Living Faith Church Worldwide (Winners' Chapel).",
        location: "Nigeria"
    },
    {
        name: "Shepherd Bushiri",
        wealth: "$150 million",
        description: "Founder of Enlightened Christian Gathering.",
        location: "South Africa"
    },
    {
        name: "Pat Robertson",
        wealth: "$100 million",
        description: "Founder of the Christian Broadcasting Network.",
        location: "USA"
    },
    {
        name: "Joel Osteen",
        wealth: "$100 million",
        description: "Senior pastor of Lakewood Church.",
        location: "USA",
        profile: "/pastors/Joel_Osteen.jpg"
    },
    {
        name: "Chris Oyakhilome",
        wealth: "$126 million",
        description: "Founder of Christ Embassy (Believers' LoveWorld).",
        location: "Nigeria"
    },
    {
        name: "Benny Hinn",
        wealth: "$60 million",
        description: "Israeli-born televangelist known for his 'Miracle Crusades'.",
        location: "USA"
    },
    {
        name: "David Jeremiah",
        wealth: "$55 million",
        description: "Senior pastor of Shadow Mountain Community Church and founder of Turning Point Radio and Television Ministries.",
        location: "USA"
    },
    {
        name: "Romildo Ribeiro Soares (R.R. Soares)",
        wealth: "$125 million",
        description: "Brazilian televangelist and founder of the International Church of God's Grace.",
        location: "Brazil"
    },
    {
        name: "Steven Furtick",
        wealth: "$60 million",
        description: "Founder and lead pastor of Elevation Church.",
        location: "USA"
    },
    {
        name: "Creflo Dollar",
        wealth: "$30 million",
        description: "Founder of World Changers Church International.",
        location: "USA"
    },
    {
        name: "Rick Warren",
        wealth: "$25 million",
        description: "Founder of Saddleback Church.",
        location: "USA"
    },
    {
        name: "T.D. Jakes",
        wealth: "$20 million",
        description: "Founder of The Potter's House.",
        location: "USA"
    },
    {
        name: "Bill Hybels",
        wealth: "$40 million",
        description: "Founder of Willow Creek Community Church.",
        location: "USA"
    },
    {
        name: "Joyce Meyer",
        wealth: "$8 million",
        description: "Founder of Joyce Meyer Ministries.",
        location: "USA"
    },
    {
        name: "Joseph Prince",
        wealth: "$5 million",
        description: "Senior pastor of New Creation Church.",
        location: "Singapore"
    },
    {
        name: "Franklin Graham",
        wealth: "$10 million",
        description: "President of the Billy Graham Evangelistic Association and Samaritan's Purse.",
        location: "USA"
    }
]
