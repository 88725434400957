import React, {useState, useEffect} from 'react';
import {Link, useNavigation} from "react-router-dom";
import {IoArrowBack, IoChevronBack} from "react-icons/io5";

/**
 * BackButton
 * Created on 2/11/24
 */
const BackButton = ({ location }: {location?: string}) => (
    <div>
        <Link to={location || '../'}>
            <span className={'text-lg text-blue-500 font-bold flex items-center my-2'}>
                <IoChevronBack size={24} />&nbsp;
                <span>Back</span>
            </span>
        </Link>
    </div>
)

export default BackButton;
