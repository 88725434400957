import React from 'react';

const AboutPage = () => {

    return (
        <div className={"w-full md:container-sm space-y-10 text-lg mt-5 px-4"}>
            <p>Red Sentinel is dedicated to becoming a database chronicling instances of corporate, political
                and societal misconduct. By collating information from various sources across the internet, we provide a
                singular platform, accessible to all, that offers insights into instances where influential entities may
                have misused their power for personal advantage.</p>

            <p>Our focus is on significant events that, for various reasons, might not have received the attention they
                warranted, or went largely unpunished due to the size, power, or influence of the entities involved.
                Examples include situations akin to the HSBC money laundering incident, revelations like the Paradise
                and Panama Papers, or underreported tragedies such as the tragic demise of Daphne Caruana Galizia.</p>

            <p>It's not uncommon for such pivotal events to be overshadowed by contemporary distractions, allowing them
                to fade from public memory. Yet, it is essential to recall these occurrences, not only to hold
                accountable those who evade justice but to maintain a transparent and informed society.</p>

            <p>Project Purity seeks to ensure that history does not easily forget. Whether it's a corporation
                mishandling a nation's data, financial institutions indirectly supporting illicit activities,
                pharmaceutical firms contributing to drug crises, or influential individuals engaging in financial
                secrecy, our platform is committed to preserving and highlighting these stories.</p>
        </div>
    );
}

export default AboutPage;
