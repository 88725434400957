import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
const parse = require('html-react-parser');

const ArticlePage = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState<any>();

  useEffect(() => {
    const getArticle = async () => {
      try {
        const result = await axios.get(`${process.env.REACT_APP_API}/article/${articleId}`);
        if (result.data) {
          setArticle(result.data);
        }
      } catch (error) {
        console.log('Unable to retrieve article');
        console.log(error);
      }
    };

    getArticle();
  }, [articleId]);

  console.log(articleId);

  if (!article) {
    return <p>Loading...</p>;
  }

  return (
    <div className="w-[800px] px-4 mx-auto mt-6">
      <article>
        <img src={article.cover} alt="" className="rounded-lg" />
        <h1 className="mb-4 text-3xl">{article.title}</h1>
        <div>{parse(article.content)}</div>
      </article>
    </div>
  );
};

export default ArticlePage;
