import React from "react";
import {IoLogoFacebook, IoLogoTwitter} from "react-icons/io5";

export const SocialProfiles = ({ member }: any) => (
    <div className="mt-3 flex items-center space-x-4">
        {member.facebook_account && (
            <a
                href={`https://facebook.com/${member.facebook_account}`}
                target="_blank"
                rel="noreferrer"
            >
                <IoLogoFacebook size={32} color={"lightblue"} />
            </a>
        )}
        {member.twitter_account && (
            <a
                href={`https://twitter.com/${member.twitter_account}`}
                target="_blank"
                rel="noreferrer"
            >
                <IoLogoTwitter size={32} color={"lightblue"} />
            </a>
        )}
    </div>
);
