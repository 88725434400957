import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {SocialProfiles} from "./Member/SocialProfiles";
import {Roles} from "./Member/Roles";
import {BillList} from "./Bills/BillList";
import {MemberExpenses} from "./Member/MemberExpenses";
import Loading from "../Components/Loading";
import {TravelList} from "./Member/TravelList";
import BackButton from "../Components/BackButton";
import {IoPersonCircle} from "react-icons/io5";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {cache, getCached, isCached} from "../Services/Cache";

const ProfilePage = () => {
    const {memberId} = useParams();
    const [member, setMember] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const [billType, setBillType] = useState('introduced');
    const [expenseQuarter, setExpenseQuarter] = useState('[1, 24]');

    useEffect(() => {
        const endpoint = `/gov/member/${memberId}`;

        if (isCached(endpoint)) {
            setMember(getCached(endpoint));
            setIsLoading(false);
        } else {
            axios.get(`${process.env.REACT_APP_API}${endpoint}`).then((data) => {
                setMember(data.data);
                cache(endpoint, data.data);
                setIsLoading(false);
            }).catch(err => console.log(err.response.data));
        }
    }, [memberId]);

    if (!member?.id) {
        return <Loading/>
    }

    return (
        <div className={"px-4"}>
            {/*<header className={member.current_party === "R" ? "bg-[#E81B23] -mx-4" : "bg-[#00AEF3] -mx-4"}>*/}
            <header className={"bg-slate-700 -mx-4"}>
                <div className="container mx-auto py-16">
                    <BackButton location={"/members"}/>
                    <div className={"flex items-start"}>
                        <div className="w-auto mr-6">
                            <IoPersonCircle size={128} color={"white"}/>
                        </div>
                        <div>
                            <p className={"text-4xl text-white"}>
                                {member.first_name} {member.last_name} (<small>{member.current_party === 'D' ? 'Democrat' : 'Republican'}</small>)
                            </p>
                            <SocialProfiles member={member}/>
                        </div>
                    </div>
                </div>
            </header>

            <div className={'container mx-auto my-4 flex flex-col-reverse lg:flex-row lg:space-x-4'}>
                {/*<div className={"w-full lg:w-3/4 flex-shrink-0"}>*/}

                {/*    <Tabs>*/}
                {/*        /!**/}
                {/*            TODO*/}
                {/*            Wrap tab buttons into a card so this side starts at the same height as the aside*/}
                {/*        *!/*/}
                {/*        <TabList className={"flex space-x-4 mb-2"}>*/}
                {/*            <Tab className={"px-4 py-2 cursor-pointer outline-0"}>Bills</Tab>*/}
                {/*            <Tab className={"px-4 py-2 cursor-pointer outline-0"}>Office Expenses</Tab>*/}
                {/*            <Tab className={"px-4 py-2 cursor-pointer outline-0"}>Privately Funded Travel</Tab>*/}
                {/*        </TabList>*/}

                {/*        <TabPanel>*/}
                {/*            <div className="card mb-3">*/}
                {/*                <div className="-mx-4 -mt-4 mb-2 p-4 border-b border-gray-400">*/}
                {/*                    <div className={"flex justify-between items-center"}>*/}

                {/*                        <div>*/}
                {/*                            <h2 className={"text-lg font-semibold"}>Bills</h2>*/}
                {/*                            <p className={"text-sm"}>View the 20 bills most recently introduced or*/}
                {/*                                updated*/}
                {/*                                by {member.first_name}&nbsp;{member.last_name}.</p>*/}
                {/*                        </div>*/}

                {/*                        <select className={"input w-auto"} name="billType" id="billType"*/}
                {/*                                onChange={(e: any) => setBillType(e.target.value)}>*/}
                {/*                            <option value="Introduced">Introduced</option>*/}
                {/*                            <option value="Active">Active</option>*/}
                {/*                            <option value="Updated">Updated</option>*/}
                {/*                            <option value="Vetoed">Vetoed</option>*/}
                {/*                            <option value="Passed">Passed</option>*/}
                {/*                            <option value="Enacted">Enacted</option>*/}
                {/*                        </select>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <BillList memberId={member.id} type={billType}/>*/}
                {/*            </div>*/}
                {/*        </TabPanel>*/}
                {/*        <TabPanel>*/}
                {/*            <div className="card mb-3">*/}
                {/*                <div className="-mx-4 -mt-4 mb-2 p-4 border-b border-gray-400">*/}
                {/*                    <div className={"flex justify-between items-center"}>*/}
                {/*                        <h2 className={"text-lg font-semibold"}>Office Expenses</h2>*/}
                {/*                        <select className={"input w-auto"} name="expenseQuarter" id="expenseQuarter"*/}
                {/*                                onChange={(e: any) => setExpenseQuarter(e.target.value)}>*/}
                {/*                            <option value="[1, 24]">Q1 - 2024</option>*/}
                {/*                            <option value="[4, 23]">Q4 - 2023</option>*/}
                {/*                            <option value="[3, 23]">Q3 - 2023</option>*/}
                {/*                            <option value="[2, 23]">Q2 - 2023</option>*/}
                {/*                            <option value="[1, 23]">Q1 - 2023</option>*/}
                {/*                            <option value="[4, 22]">Q4 - 2022</option>*/}
                {/*                            <option value="[3, 22]">Q3 - 2022</option>*/}
                {/*                            <option value="[2, 22]">Q2 - 2022</option>*/}
                {/*                            <option value="[1, 22]">Q1 - 2022</option>*/}
                {/*                            <option value="[4, 21]">Q4 - 2021</option>*/}
                {/*                            <option value="[3, 21]">Q3 - 2021</option>*/}
                {/*                            <option value="[2, 21]">Q2 - 2021</option>*/}
                {/*                            <option value="[1, 21]">Q1 - 2021</option>*/}
                {/*                            <option value="[4, 20]">Q4 - 2020</option>*/}
                {/*                            <option value="[3, 20]">Q3 - 2020</option>*/}
                {/*                            <option value="[2, 20]">Q2 - 2020</option>*/}
                {/*                            <option value="[1, 20]">Q1 - 2020</option>*/}
                {/*                        </select>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <MemberExpenses memberId={member.id} period={expenseQuarter}/>*/}
                {/*            </div>*/}
                {/*        </TabPanel>*/}
                {/*        <TabPanel>*/}
                {/*            <div className="card mb-3">*/}
                {/*                <div className="-mx-4 -mt-4 mb-2 p-4 border-b border-gray-400">*/}
                {/*                    <h2 className={"text-lg font-semibold"}>Privately Funded Travel</h2>*/}
                {/*                </div>*/}

                {/*                <TravelList memberId={member.id}/>*/}
                {/*            </div>*/}
                {/*        </TabPanel>*/}
                {/*    </Tabs>*/}
                {/*</div>*/}

                <aside className={"w-full mb-4 lg:mb-0"}>
                    <Roles roles={member.roles}/>

                    <div className="card mb-4">
                        <strong>Profile info</strong>

                        <ul className={"space-y-2 mt-4"}>
                            <li>
                                <strong>Full name:</strong>
                                &nbsp;
                                <span>{member.first_name} {member.middle_name} {member.last_name} {member.suffix}</span>
                            </li>

                            <li>
                                <strong>Date of birth:</strong>
                                &nbsp;
                                <span>{member.date_of_birth}</span>
                            </li>

                            <li>
                                <strong>Currently in office:</strong>
                                &nbsp;
                                <span>{member.in_office ? "Yes" : "No"}</span>
                            </li>

                            <li>
                                <a className={"text-sky-500 hover:underline"} href={`${member.url}?ref=sentinel.red`}
                                   target={"_blank"}>Official page</a>
                            </li>

                            <li>
                                <a className={"text-sky-500 hover:underline"}
                                   href={`https://www.opensecrets.org/members-of-congress/summary?cid=${member.crp_id}&ref=sentinel.red`}
                                   target="_blank">Open Secrets</a>
                            </li>
                        </ul>
                    </div>

                    <div className="card">
                        <strong>Member roles</strong>

                        <ul className={"divide-y-2 space-y-2 mt-4"}>
                            {
                                member.roles.map((role: any, i: number) => (
                                        <li className={"mb-4"} key={i}>
                                            <div>Date: {role.start_date} - {role.end_date}</div>
                                            <div>{role.chamber} {role.title} of {role.state}</div>
                                            <div>District {role.district}</div>
                                            <div>Congress {role.congress}</div>
                                            <div>Sponsored bills: {role.bills_sponsored}</div>
                                            <div>Cosponsored bills: {role.bills_cosponsored}</div>
                                        </li>
                                    )
                                )
                            }
                        </ul>
                    </div>
                </aside>
            </div>
        </div>
    );
};

export default ProfilePage;
