import React from 'react';
import packageJson from "../../package.json";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="text-center mb-4 mt-10">
            <p className="text-sm text-gray-700">&copy; Red Sentinel {year}</p>
            <small>v{packageJson.version}</small>
        </footer>
    );
};
export default Footer;
