import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import ArticleCard from "../../Components/ArticleCard";
import Loading from "../../Components/Loading";
import BackButton from "../../Components/BackButton";

const EventPage = () => {
    const {eventId} = useParams();
    const [event, setEvent] = useState<any>();
    const [eventNews, setEventNews] = useState<any[]>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getEvent = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API}/event/${eventId}`);

                if (result.data) {
                    setEvent(result.data);
                }
            } catch (error) {
                console.log('Cannot retrieve event');
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        const getEventNews = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API}/event/${eventId}/articles`);

                if (result.data) {
                    setEventNews(result.data);
                }
            } catch (error) {
                console.log('Cannot retrieve event news');
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        getEvent();
        getEventNews();
    }, [eventId]);

    if (loading || !event || !eventNews) {
        return (
            <Loading />
        );
    }

    return (
        <div>
            <header className="w-full h-auto relative">
                <div
                    className="h-full w-full absolute"
                    style={{
                        backgroundImage: `url(${event.cover})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        filter: 'brightness(0.5)',
                    }}
                ></div>

                <div
                    className="relative z-10 text-white min-h-full w-full lg:w-2/3 mx-auto px-4 py-20 flex-col text-center">
                    <h1 className="font-bold">{event.title}</h1>
                </div>
            </header>

            <div className={'flex flex-col md:flex-row container mx-auto mt-4 px-4'}>
                <div className={'w-full'}>
                    <div className="mb-8">
                        <BackButton />
                        <h2 className={"headline"}>Summary</h2>
                        <p>{event.summary}</p>
                    </div>

                    <section>
                        <div className="container mx-auto">
                            <h2 className="headline">Articles</h2>
                            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                                {eventNews.length ? eventNews.map((article) => (
                                    <ArticleCard article={article} key={article.id} />
                                )) : <p>No articles yet for this event.</p>}
                            </div>
                        </div>
                    </section>
                </div>

                <aside className={"w-1/3 md:ml-6 mt-6 md:mt-0"}>
                    <div>
                        <h3 className={"font-semibold mb-2"}>Tags</h3>
                        <ul className={"flex space-x-2"}>
                            {event.tags?.map(tag => <li key={tag.id}
                                className={"text-xs border border-gray-500 rounded-md p-1"}>{tag.label}</li>)}
                        </ul>
                    </div>
                    {/*<div>*/}
                    {/*    <h2 className={'headline'}>In this event</h2>*/}
                    {/*    <EntityAvatar entity={{*/}
                    {/*        thumbnail: 'https://placekitten.com/g/45/45',*/}
                    {/*        name: 'Facebook'*/}
                    {/*    }}/>*/}
                    {/*</div>*/}
                </aside>
            </div>
        </div>
    );
};

export default EventPage;
