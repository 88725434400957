import React from 'react';

const Hero = () => {
    return (
        <div className="bg-slate-800 text-white pb-16 pt-4 px-6 mb-6">
            <div className="container mx-auto mt-2">
                <h1 className="text-5xl mb-2">{process.env.REACT_APP_TITLE}</h1>
                <p className={"text-lg"}>Keeping track of the world's major corporate and government misconduct.</p>
            </div>
        </div>
    );
};

export default Hero;
