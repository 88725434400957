import React from 'react';

/**
 * SupportPage
 * Created on 2/5/24
 */
const SupportPage = () => {
    return (
        <div className={"container-sm text-lg pt-6"}>
            <p>Hey there,</p>
            <br/>
            <p>
                You're probably viewing this page because you know we could use a hand to keep this website going strong!
            </p>
            <br/>
            <p>
                If you've been
                enjoying our content and find it helpful, please consider donating. Your contributions will go straight
                into two buckets: one to keep the servers humming along so you can access our stuff without a hitch, and
                the other to amp up your overall experience on our site.
            </p>
            <p>

                Every little bit counts, and your support means the
                world to us. So, if you've got a few bucks to spare, help us keep the digital lights on and make this
                place even better. Thanks a million for being part of our community!
            </p>

            <br/>

            <br/>

            <div className="text-center mt-5">
                <a href="https://www.buymeacoffee.com/redsentinel" rel={"noreferrer"} target={"_blank"} className={"button"}>Donate here</a>
            </div>
        </div>
    );
}

export default SupportPage;
