export const getAge = (date) => {
    return new Date().getFullYear() - parseInt(date.split("-")[0]);
};

export const getYear = (date) => {
    return parseInt(date.split("-")[0]);
};

export const formatDate = (dateString) => new Date(dateString).toLocaleString('en-US', {
    dateStyle: 'medium'
});
